import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <article>
      <h1>NOT FOUND</h1>
      <p>Sorry, this page does not exist. Please <Link to="/">Go back home</Link> and try again.</p>
    </article>
  </Layout>
)

export default NotFoundPage
